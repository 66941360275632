//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Text } from '@dt/material-components';
import { CompliancePolicyValues } from '@dt/graphql-support/types';
import type { CompliancePolicy } from '@dt/graphql-support/types';
import Box from '@material-ui/core/Box';

export type PopoutFilterPluginCompliancePolicyValues = {
  [key: CompliancePolicy]: boolean,
  ...,
};

const CompliancePolicySelection: $Exact<
  $ObjMap<typeof CompliancePolicyValues, <V>() => string>,
> = {
  [CompliancePolicyValues.CALOPPA]: 'CalOPPA',
  [CompliancePolicyValues.CCPA]: 'CCPA',
  [CompliancePolicyValues.COPPA]: 'COPPA',
  [CompliancePolicyValues.FTC]: 'FTC',
  [CompliancePolicyValues.GDPR]: 'GDPR',
  [CompliancePolicyValues.HIPAA]: 'HIPAA',
  [CompliancePolicyValues.MITRE]: 'MITRE',
  [CompliancePolicyValues.OWASP]: 'OWASP',
  [CompliancePolicyValues.PCI]: 'PCI',
  [CompliancePolicyValues.VPPA]: 'VPPA',
};
type Props = {
  +value: PopoutFilterPluginCompliancePolicyValues,
  +onChange: (
    (
      value: PopoutFilterPluginCompliancePolicyValues,
    ) => PopoutFilterPluginCompliancePolicyValues,
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select compliance policies.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */

export const PopoutFilterPluginCompliancePolicy = ({
  value,
  onChange,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(CompliancePolicySelection).map(key => {
        const val = CompliancePolicySelection[key];
        return (
          <FormControlLabel
            key={key}
            label={<Text variant="body">{val}</Text>}
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                checked={!!value[key]}
                onChange={e => {
                  (value =>
                    onChange(prev => ({
                      ...prev,
                      // $FlowFixMe - string -> enum
                      [key]: value,
                    })))(e.target.checked);
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};
