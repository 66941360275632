//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Tag } from '@dt/material-components';
import { palette } from '@dt/theme';
import { TargetStatusValues } from '@dt/graphql-support/types';
import type { TargetStatus } from '@dt/graphql-support/types';
import Box from '@material-ui/core/Box';

export type PopoutFilterPluginTargetStatusValues = {
  [key: TargetStatus]: boolean,
  ...,
};

const TargetStatusSelection: $Exact<
  $ObjMap<typeof TargetStatusValues, <V>() => string>,
> = {
  [TargetStatusValues.OPEN]: 'OPEN',
  [TargetStatusValues.OPEN_READY_TO_RESCAN]: 'OPEN READY TO RESCAN',
  [TargetStatusValues.NEW]: 'NEW',
  [TargetStatusValues.CLOSED_FIXED]: 'FIXED',
  [TargetStatusValues.CLOSED_ITEM_NOT_FOUND]: 'NOT FOUND',
  [TargetStatusValues.CLOSED_RISK_ACCEPTED]: 'RISK ACCEPTED',
  [TargetStatusValues.CLOSED_BY_POLICY]: 'CLOSED BY POLICY',
  [TargetStatusValues.CLOSED_COMPENSATING_CONTROL]: 'COMPENSATING CONTROL',
};
type Props = {
  +value: PopoutFilterPluginTargetStatusValues,
  +onChange: (
    (
      value: PopoutFilterPluginTargetStatusValues,
    ) => PopoutFilterPluginTargetStatusValues,
  ) => void,
};

const colorMap = {
  [TargetStatusValues.OPEN]: {
    bgColor: palette.red50,
    fgColor: palette.red10,
  },
  [TargetStatusValues.OPEN_READY_TO_RESCAN]: {
    bgColor: palette.red50,
    fgColor: palette.red10,
  },
  [TargetStatusValues.NEW]: {
    bgColor: palette.red50,
    fgColor: palette.red10,
  },
  [TargetStatusValues.CLOSED_FIXED]: {
    bgColor: palette.green50,
    fgColor: palette.green10,
  },
  [TargetStatusValues.CLOSED_ITEM_NOT_FOUND]: {
    bgColor: palette.green50,
    fgColor: palette.green10,
  },
  [TargetStatusValues.CLOSED_RISK_ACCEPTED]: {
    bgColor: palette.gray50,
    fgColor: palette.gray10,
  },
  [TargetStatusValues.CLOSED_BY_POLICY]: {
    bgColor: palette.gray50,
    fgColor: palette.gray10,
  },
  [TargetStatusValues.CLOSED_COMPENSATING_CONTROL]: {
    bgColor: palette.gray50,
    fgColor: palette.gray10,
  },
};

/*
 * Popout filter plugin.
 * User can select target statuses.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */

export const PopoutFilterPluginTargetStatus = ({ value, onChange }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(TargetStatusSelection).map(key => {
        const val = TargetStatusSelection[key];
        return (
          <FormControlLabel
            key={key}
            label={
              <Tag
                label={val}
                backgroundColor={colorMap[key].bgColor}
                color={colorMap[key].fgColor}
              />
            }
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                checked={!!value[key]}
                onChange={e => {
                  (value =>
                    onChange(prev => ({
                      ...prev,
                      // $FlowFixMe - string -> enum
                      [key]: value,
                    })))(e.target.checked);
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};
