//@flow
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Tag } from '@dt/material-components';
import { palette } from '@dt/theme';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/types';
import Box from '@material-ui/core/Box';

export type PopoutFilterPluginStatusValues = {
  RESOLVED?: boolean,
  CLOSED?: boolean,
  OPEN?: boolean,
  ...
};

type Props = {
  +value: PopoutFilterPluginStatusValues,
  +onChange: (
    (value: PopoutFilterPluginStatusValues) => PopoutFilterPluginStatusValues,
  ) => void,
};

/*
 * Popout filter plugin.
 * User can select statuses.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginStatusComponent = function PopoutFilterPluginStatus({
  value,
  onChange,
}: Props) {
  return (
    <Box display="flex" flexDirection="column">
      {[
        {
          value: PolicyViolationStatusEnumValues.RESOLVED,
          bgColor: palette.green50,
          fgColor: palette.green10,
        },
        {
          // NOTE: Not directly represented by backend enum type.
          value: 'CLOSED',
          bgColor: palette.gray45,
          fgColor: palette.gray20,
        },
        {
          value: PolicyViolationStatusEnumValues.OPEN,
          bgColor: palette.red50,
          fgColor: palette.red10,
        },
      ].map(status => (
        <FormControlLabel
          key={status.value}
          label={
            <Tag
              label={status.value}
              backgroundColor={status.bgColor}
              color={status.fgColor}
            />
          }
          control={
            <Checkbox
              color="primary"
              inputProps={{
                'aria-label': `${status.value}`,
              }}
              checked={!!value[status.value]}
              onChange={e => {
                (value =>
                  onChange(prev => ({
                    ...prev,
                    // $FlowFixMe - string -> enum
                    [status.value]: value,
                  })))(e.target.checked);
              }}
            />
          }
        />
      ))}
    </Box>
  );
};

export const PopoutFilterPluginStatus = PopoutFilterPluginStatusComponent;
